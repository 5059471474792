import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
library.add(faXmark);

import 'apps-web/src/web-components/a.ts';
import 'apps-web/src/web-components/dialog.ts';

import './web-components/event-list.ts';
import './web-components/event-map.ts';
import './web-components/events.ts';
import './web-components/quote.ts';
